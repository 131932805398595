.form-control-feedback {
    border: 1px solid #ffe5e5;
    background: #c51244 !important;
    padding: 2px 10px !important;
    border-radius: 0 !important;
    position: absolute;
    display: inline-block !important;
    box-shadow: 1px 1px 9px #aaa;
    margin-top: 0;
    color: #fff;
    right: -4px;
    z-index: 999999;
    white-space: nowrap;
    top: -18px;
    font-size: 12px;
    top: -16px;
    font-size: 12px;
    line-height: 14px;
}

.form-control-feedback:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #c51244;
    position: absolute;
    bottom: -8px;
    right: 6px;
}

.ck-editor__editable_inline {
    min-height: 400px !important;
    max-height: 400px !important;
    overflow: auto;
}

.ck-editor__editable_inline {
    min-height: 400px !important;
    max-height: 400px !important;
    overflow: auto;
}



/* Scrollbar */
::-webkit-scrollbar {
    width: 7px;
    cursor: pointer;
    height: 7px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ddd;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
    opacity: 0.01;
}

::-webkit-scrollbar-thumb:hover {
    background: #f89854;
}

/* button function above table */
.vs-data-table-area .__top-table-action {
    background-color: #fff;
    padding-bottom: 5px;
    padding-top: 5px;
}

.vs-btn.vs-top-table-btn {
    background: #1ca7ff;
    border-radius: 0px;
    transition: border-color 0.3s ease-out;
    color: #fff;
    margin-right: 5px;
}

.btn-selected {
    background-color: #ccc !important;
    border-color: #eee !important;
}

.vs-btn.vs-top-table-btn .__icon {
    color: #fff !important;
}

.vs-btn.vs-top-table-btn:hover {
    background-color: #0095da;
    border-color: #0672a5;
}

.disable {
    cursor: not-allowed;
    background-color: #e5e5e5 !important;
    border-color: #aaa !important;
    color: #525252 !important;
}

@media screen and (max-device-width: 1400px) {
    .custom-dialog-call {
        transform: scale(0.8) translate(56px, 56px);
    }
}